import React from "react";
import P from "../../components/CustomTexts/P";

interface PricingDetailsTabProps {
  description: string; // Ensure it expects a string
}

const PricingDetailsTab: React.FC<PricingDetailsTabProps> = ({ description }) => {
  return (
    <div className="mt-10">
      <P mode="light" align="center">
        {description}
      </P>
    </div>
  );
};

export default PricingDetailsTab;
